import { render, staticRenderFns } from "./CButtons_separate.vue?vue&type=template&id=07aedf2e&scoped=true"
import script from "./CButtons_separate.vue?vue&type=script&lang=js"
export * from "./CButtons_separate.vue?vue&type=script&lang=js"
import style0 from "./CButtons_separate.vue?vue&type=style&index=0&id=07aedf2e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07aedf2e",
  null
  
)

export default component.exports